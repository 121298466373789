import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Message, ChatItem, User } from '../types/chat';
import socketService from '../services/socketService';

import { FiLoader, FiChevronLeft, FiChevronRight, FiClock, FiX, FiUser, FiMenu, FiHome, FiFileText, FiUpload, FiChevronDown } from 'react-icons/fi';
import { RiRobotLine } from 'react-icons/ri';
import { getPromptInfo } from '../services/systemPrompts';
import { Sparkles, FileText, Send } from 'lucide-react';
import SystemPromptTerminal from '../components/SystemPromptTerminal';

interface Upload {
  unique_id: string;
  title: string;
  file_name_list: string[];
  created_at_formatted: string;
  file_count: number;
}

const ChatPage: React.FC = () => {
  const { chatId } = useParams<{ chatId: string }>();
  const [messages, setMessages] = useState<Message[]>(() => {
    const savedMessages = sessionStorage.getItem(`chat_${chatId}`);
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [availableFiles, setAvailableFiles] = useState<string[]>([]);
  const [uploadMetadata, setUploadMetadata] = useState<any>(null);
  const [isLoadingMetadata, setIsLoadingMetadata] = useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [recentUploads, setRecentUploads] = useState<Upload[]>([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const navigate = useNavigate();
  const [showNavDropdown, setShowNavDropdown] = useState(false);
  const [reload, setReload] = useState(false);
  const currentUser: User = {
    name: 'John Doe',
    avatar: '/user-avatar.png',
  };

  // Add socket connection management
  useEffect(() => {
    let mounted = true;
    let socketCleanup: (() => void) | null = null;

    const initializeSocket = async () => {
      try {
        const socket = await socketService.getSocket();
        if (!mounted) {
          socketService.disconnect();
          return;
        }
        if (socket) {
          socketCleanup = () => {
            socket.removeAllListeners();
            socketService.disconnect();
          };
        }
      } catch (error) {
        console.error('Failed to initialize socket:', error);
      }
    };

    initializeSocket();

    return () => {
      mounted = false;
      if (socketCleanup) {
        socketCleanup();
      }
      socketService.disconnect();
    };
  }, [chatId]);

  // Modify fetchMetadata to handle connection issues
  const fetchMetadata = async () => {
    setIsLoadingMetadata(true);
    if (availableFiles.length > 0) {
      // console.log(`availableFiles: ${availableFiles}`)
      setIsLoadingMetadata(false);
      return true;
    }
    
    if(isLoadingMetadata){
      console.log("metadata is loading")
      return false; // Return false so the useEffect wont stop trying to fetch metadata
    }
    if (!chatId) return;
    
      try{
        const socket = await socketService.getSocket();
        if (!socket) {
          throw new Error('No socket connection available');
        }
        const response = await fetch(`${window.env.REACT_APP_BACKEND_URL}/uploads/${chatId}`);
        if (!response.ok) throw new Error('Failed to fetch metadata');
        const data = await response.json();
        if (!data) return;
        
        setUploadMetadata(data);
        const files = data.file_name_list || [];
        if(files.length <= 0 ) return false;
        setAvailableFiles(files);
        setSelectedFiles(files);
        setIsLoadingMetadata(false);
        return true;
      }
      catch(error) {
        console.log("Could not fetch metadata[RETRYING]")
        setIsLoadingMetadata(false);
        return false;
      }
  };

  // Fetch suggestions with better error handling
  const fetchSuggestions = async () => {
    setIsLoadingSuggestions(true);
    try{
      if (suggestions.length > 0){
        // console.log(`suggestions: ${suggestions}`)
        setIsLoadingSuggestions(false);
        return true;
      }
      // console.log('fetching suggestions')
      if (!chatId) return;

      const controller = new AbortController();
      const signal = controller.signal;

      // Set a timeout to abort the request
      const timeoutId = setTimeout(() => controller.abort(), 5000);
      const response = await fetch(`${window.env.REACT_APP_BACKEND_URL}/chat/suggestions/${chatId}`,
        {signal});
      clearTimeout(timeoutId);
      if (!response.ok) throw new Error('Failed to fetch suggestions');
      const data = await response.json();
      // if (!data) return ;
      
      if (data.suggestions.length > 0 && suggestions.length <= 0){
        setSuggestions(data.suggestions || []);
        setIsLoadingSuggestions(false);
        return true;
      }
      console.log(suggestions.length <= 0 ? 'no suggestions' : 'Done processing suggestions')
      setIsLoadingSuggestions(false);
      return false;
    }
    catch(error){
      console.log("Could not fetch suggestions[RETRYING]")
      setIsLoadingSuggestions(false);
      return false;
    }
    
  };

  // Fetch data only when suggestions are empty
  useEffect(() => {
    console.log('starting process of fetching data')
    let dontReload = true;
    if(suggestions.length > 0 && availableFiles.length > 0){

      // console.log(`suggestions: ${suggestions}`)
      // console.log(`suggestions: ${suggestions.length}`)
      // console.log(`availableFiles: ${availableFiles.length}`)
      // console.log("Skipping because we have suggestions and available files");
      setIsLoadingSuggestions(false);
      setIsLoadingMetadata(false);
      return;
    }
    console.log('ATTEMPTING TO FETCH SUGGESTIONS AND METADATA')
    // setReload(false);

    try{
      // Separately fetch suggestions and metadata
      const fetchData = async () => {
      // if(!isLoadingSuggestions){
      //   // console.log("Suggestions are not loading")
      //   setIsLoadingSuggestions(true);
      //   dontReload = (await fetchSuggestions() || false) && suggestions.length > 0;
      // }
      // setIsLoadingSuggestions(true);
      dontReload = (await fetchSuggestions() || false) && dontReload;
      dontReload = (await fetchMetadata() || false) && dontReload;
      
      // if(!isLoadingMetadata){
      //   // console.log("Metadata is not loading")
      //   setIsLoadingMetadata(true);
      //   dontReload = (await fetchMetadata() || false) && dontReload;
      // }
      };
      // console.log('fetching data')
      fetchData();

    }catch(error){
      console.error("Error during fetching[RETRYING]");
      setIsLoadingSuggestions(false);
      setIsLoadingMetadata(false);
      // console.error("Error during fetching:", error);
      // setReload(true);
    }
    
  }); // Only re-run when chatId changes

  // Handle scroll
  const handleScroll = () => {
    if (messageContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
      const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 100;
      setShouldAutoScroll(isNearBottom);
    }
  };

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    if (shouldAutoScroll && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, shouldAutoScroll]);

  // Handle suggestion click
  const handleSuggestionClick = (suggestion: string) => {
    setInputValue(suggestion.substring(1, suggestion.length - 1));
  };

  // Modify handleSendMessage to handle connection issues
  const handleSendMessage = async (inputMessage: string) => {
    if (!inputMessage.trim() || isTyping) return;
    
    setIsTyping(true);
    const newMessage: Message = {
      id: Date.now(),
      text: inputMessage,
      sender: 'user',
      timestamp: new Date(),
    };
    setMessages(prev => [...prev, newMessage]);
    setInputValue('');

    try {
      const socket = await socketService.getSocket();
      if (!socket) {
        throw new Error('No socket connection available');
      }

      const response = await fetch(`${window.env.REACT_APP_BACKEND_URL}/chat/upload_id/${chatId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: inputMessage,
          files_list: selectedFiles,
          previous_messages: messages.slice(-5),
        }),
      });

      if (!response.ok) throw new Error(`response is not ok Error: ${response.statusText}`);
      const data = await response.json();
      
      if (!data.ai_message) throw new Error('No response from AI');
      
      const cleanMessage = data.ai_message
        .replace(/<[^>]*>/g, '')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'");
      
      const aiResponse: Message = {
        id: Date.now(),
        text: cleanMessage,
        sender: 'ai',
        timestamp: new Date(),
        metadata: data.metadata || {},
      };
      setMessages(prev => [...prev, aiResponse]);
    } catch (error) {
      console.error('Error in chat interaction:', error);
      const errorResponse: Message = {
        id: Date.now(),
        text: 'Sorry, I encountered an error while processing your request. Please try again.',
        sender: 'ai',
        timestamp: new Date(),
      };
      setMessages(prev => [...prev, errorResponse]);
    } finally {
      setIsTyping(false);
    }
  };

  // Get prompt info for chat
  const promptInfo = getPromptInfo('chat');

  // Add a function to handle file selection
  const handleFileSelection = (file: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedFiles(prev => [...prev, file]);
    } else {
      setSelectedFiles(prev => prev.filter(f => f !== file));
    }
  };

  // Add new useEffect for fetching uploads
  useEffect(() => {
    const fetchRecentUploads = async () => {
      if (!showHistory) return;
      setIsLoadingHistory(true);
      try {
        const response = await fetch(`${window.env.REACT_APP_BACKEND_URL}/chat/uploads`);
        if (!response.ok) throw new Error('Failed to fetch uploads');
        const data = await response.json();
        setRecentUploads(data.uploads || []);
      } catch (error) {
        console.error('Error fetching uploads:', error);
      } finally {
        setIsLoadingHistory(false);
      }
    };

    if (showHistory) {
      fetchRecentUploads();
    }
  }, [showHistory]);

  // Add handleKeyPress function
  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(inputValue);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gradient-to-br from-green-50 to-white">
      {/* Sidebar */}
      <div 
        className={`
          fixed inset-y-0 left-0 transform 
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} 
          lg:relative lg:translate-x-0 transition-all duration-300 ease-in-out 
          z-30 w-full md:w-80 bg-white/90 backdrop-blur-sm border-r border-gray-200
          flex flex-col shadow-xl
          ${isSidebarOpen ? 'h-screen lg:h-auto' : 'h-auto'}
        `}
      >
        {/* Sidebar Header */}
        <div className="p-4 border-b border-gray-200 bg-white/80 backdrop-blur-sm hover:bg-white/90 transition-all duration-300">
          <h2 className="text-lg font-semibold text-green-700">
            <Link to="/">Text Miner Assistant</Link>
          </h2>
        </div>

        {/* Scrollable Sections with improved mobile layout */}
        <div className="flex-1 overflow-y-auto">
          {/* Suggestions Section */}
          <div className="p-4 border-b border-gray-200/50">
            <h3 className="text-sm font-medium text-gray-700 mb-3 flex items-center gap-2">
              <Sparkles className="w-4 h-4 text-green-600" />
              Suggestions
            </h3>
            {isLoadingSuggestions ? (
              <div className="flex justify-center py-2">
                <FiLoader className="w-5 h-5 text-green-600 animate-spin" />
              </div>
            ) : (
              <div className="space-y-2">
                {suggestions.sort().map((suggestion, index) => (
                  <button
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="w-full p-3 text-left text-sm bg-white/50 hover:bg-white rounded-lg border border-gray-200/50 hover:border-green-200 transition-all duration-300 hover:shadow-md group"
                  >
                    <span className="text-gray-600 group-hover:text-green-700 transition-colors">
                      {suggestion}
                    </span>
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Context Files Section with improved selection */}
          <div className="p-4">
            <div className="flex items-center justify-between mb-3">
              <h3 className="text-sm font-medium text-gray-700 flex items-center gap-2">
                <FileText className="w-4 h-4 text-green-600" />
                Context Files ({selectedFiles.length}/{availableFiles.length})
              </h3>
              <button
                onClick={() => {
                  const allSelected = selectedFiles.length === availableFiles.length;
                  setSelectedFiles(allSelected ? [] : [...availableFiles]);
                }}
                className="text-xs text-green-600 hover:text-green-800 transition-colors"
              >
                {selectedFiles.length === availableFiles.length ? 'Deselect All' : 'Select All'}
              </button>
            </div>
            <div className="space-y-2 max-h-[40vh] overflow-y-auto">
              {availableFiles.map((file) => (
                <label
                  key={file}
                  className="flex items-center p-3 text-sm bg-white/50 hover:bg-white rounded-lg border border-gray-200/50 hover:border-green-200 cursor-pointer group transition-all duration-300 hover:shadow-md"
                >
                  <input
                    type="checkbox"
                    className="mr-3 h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    checked={selectedFiles.includes(file)}
                    onChange={(e) => handleFileSelection(file, e.target.checked)}
                  />
                  <span className="truncate text-gray-600 group-hover:text-green-600 transition-colors">
                    {file}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile close button */}
        <button
          onClick={() => setIsSidebarOpen(false)}
          className="lg:hidden p-4 text-center text-sm text-gray-600 hover:text-green-600 transition-colors border-t border-gray-200/50"
        >
          Close Menu
        </button>
      </div>

      {/* Main chat area with improved mobile layout */}
      <div className="flex-1 flex flex-col min-w-0 relative bg-white/80 backdrop-blur-sm">
        {/* Header with improved mobile layout */}
        <div className="bg-white/90 backdrop-blur-sm border-b border-gray-200 shadow-sm">
          <div className="flex items-center justify-between px-4 md:px-6 py-4">
            {/* Left side with menu and title */}
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="p-2 rounded-full hover:bg-green-50 transition-colors lg:hidden"
              >
                {isSidebarOpen ? <FiChevronLeft size={20} /> : <FiChevronRight size={20} />}
              </button>
              <div className="flex items-center space-x-3 ml-2">
                <div className="p-2 bg-green-600 rounded-lg shadow-lg">
                  <RiRobotLine className="w-5 h-5 text-white" />
                </div>
                <div>
                  <h1 className="text-lg font-semibold bg-green-600 bg-clip-text text-transparent">
                    Chat Assistant
                  </h1>
                  <p className="text-sm text-gray-500 truncate max-w-[200px] md:max-w-full">
                    {uploadMetadata?.title || 'Loading...'}
                  </p>
                </div>
              </div>
            </div>

            {/* Right side - Recent Uploads and Navigation */}
            <div className="flex items-center gap-2">
              <button
                onClick={() => setShowHistory(!showHistory)}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:shadow-lg transition-all duration-300 flex items-center gap-2 hover:scale-105 group"
                title="Chat History"
              >
                <FiClock className="w-5 h-5 group-hover:rotate-12 transition-transform" />
                <span className="hidden md:inline">Recent Uploads</span>
              </button>

              {/* Navigation Dropdown */}
              <div className="relative z-50">
                <button
                  onClick={() => setShowNavDropdown(!showNavDropdown)}
                  className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-50 hover:border-green-200 transition-all duration-300 group shadow-sm"
                >
                  <FiMenu className="w-5 h-5 text-gray-600 group-hover:text-green-600" />
                  <span className="hidden md:inline">Navigate</span>
                  <FiChevronDown 
                    className={`w-4 h-4 text-gray-600 group-hover:text-green-600 transition-transform duration-300 ${
                      showNavDropdown ? 'rotate-180' : ''
                    }`} 
                  />
                </button>

                {showNavDropdown && (
                  <div 
                    className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-xl border border-gray-100 overflow-hidden transform origin-top scale-100 transition-all duration-200"
                    onMouseLeave={() => setShowNavDropdown(false)}
                  >
                    <div className="py-2">
                      <button
                        onClick={() => {
                         try{
                          navigate('/')
                         }catch(error){
                          console.error("Error during navigation:", error);
                         }
                        }}
                        className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gradient-to-r hover:from-green-50 hover:to-white flex items-center gap-2 group"
                      >
                        <FiHome className="w-4 h-4 text-gray-400 group-hover:text-green-600" />
                        <span>Home</span>
                      </button>
                      <button
                        onClick={() => {
                          try{
                            navigate(`/generate/${chatId}`)
                          }catch(error){
                            console.error("Error during navigation:", error);
                          }
                        }}
                        className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gradient-to-r hover:from-green-50 hover:to-white flex items-center gap-2 group"
                      >
                        <FiFileText className="w-4 h-4 text-gray-400 group-hover:text-green-600" />
                        <span>Generate Document</span>
                      </button>
                      <button
                        onClick={() => {
                          try{
                            navigate('/')
                          }catch(error){
                            console.error("Error during navigation:", error);
                          }
                          
                        }
                      }
                        className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gradient-to-r hover:from-green-50 hover:to-white flex items-center gap-2 group"
                      >
                        <FiUpload className="w-4 h-4 text-gray-400 group-hover:text-green-600" />
                        <span>New Upload</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Messages Container with improved mobile layout */}
        <div 
          ref={messageContainerRef}
          onScroll={handleScroll}
          className="flex-1 overflow-y-auto px-4 py-6"
        >
          <div className="max-w-3xl mx-auto space-y-6">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex items-start gap-3 ${
                  message.sender === 'user' ? 'justify-end' : 'justify-start'
                }`}
              >
                {message.sender === 'ai' && (
                  <div className="w-8 h-8 rounded-full bg-green-600 flex items-center justify-center shadow-lg">
                    <RiRobotLine className="w-5 h-5 text-white" />
                  </div>
                )}
                <div
                  className={`
                    max-w-[80%] rounded-2xl px-4 py-3 shadow-sm
                    ${message.sender === 'user' 
                      ? 'bg-green-600 text-white ml-4' 
                      : 'bg-white border border-gray-200/50'
                    }
                  `}
                >
                  <div className="flex items-center gap-2 mb-1">
                    <span className="text-sm font-medium">
                      {message.sender === 'ai' ? 'AI Assistant' : 'You'}
                    </span>
                    <span className="text-xs opacity-70">
                      {new Date(message.timestamp).toLocaleTimeString()}
                    </span>
                  </div>
                  <p className={`whitespace-pre-wrap ${
                    message.sender === 'user' ? 'text-white' : 'text-gray-700'
                  }`}>
                    {message.text}
                  </p>
                </div>
                {message.sender === 'user' && (
                  <div className="w-8 h-8 rounded-full bg-green-600 flex items-center justify-center shadow-lg">
                    <FiUser className="w-5 h-5 text-white" />
                  </div>
                )}
              </div>
            ))}
            {isTyping && (
              <div className="flex items-center gap-2 text-gray-500 p-4">
                <div className="w-8 h-8 rounded-full bg-green-600 flex items-center justify-center animate-pulse">
                  <RiRobotLine className="w-5 h-5 text-white" />
                </div>
                <div className="flex space-x-1">
                  <div className="w-2 h-2 bg-green-600 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                  <div className="w-2 h-2 bg-green-600 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                  <div className="w-2 h-2 bg-green-600 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Input Area with improved mobile layout */}
        <div className="border-t border-gray-200 bg-white/90 backdrop-blur-sm p-4">
          <div className="max-w-3xl mx-auto">
            <div className="flex items-center gap-2 md:gap-4">
              <textarea
                disabled={suggestions.length === 0  || isTyping }
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder={suggestions.length === 0  || isTyping ?"Loading..." :"Type your message..." }
                className="flex-1 min-h-[2.5rem] max-h-32 p-2 md:p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent resize-none bg-white/50 backdrop-blur-sm transition-all duration-300"
                rows={1}
              />
              <button
                onClick={() => handleSendMessage(inputValue)}
                disabled={!inputValue.trim() || isTyping}
                className={`
                  p-2 md:p-3 rounded-lg transition-all duration-300 flex items-center justify-center
                  ${!inputValue.trim() || isTyping
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-green-600 text-white hover:shadow-lg hover:scale-105'
                  }
                `}
              >
                <Send className="w-5 h-5" />
              </button>
            </div>
            <p className="text-xs text-gray-500 mt-2 text-center">
              Press Enter to send, Shift + Enter for new line
            </p>
          </div>
        </div>

        {/* System Prompt Terminal with improved mobile layout */}
        <div className="fixed bottom-0 right-0 z-40 p-2 md:p-4 w-full md:w-auto">
          <SystemPromptTerminal 
            systemPrompt={promptInfo.systemPrompt} 
            relatedPrompts={promptInfo.relatedPrompts}
          />
        </div>

        {/* History Modal with improved mobile layout */}
        {showHistory && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
            <div className="bg-white/90 backdrop-blur-sm rounded-2xl shadow-2xl w-full max-w-2xl border border-gray-200/50 max-h-[90vh] flex flex-col">
              <div className="p-6 border-b border-gray-200/50">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-semibold text-green-600">
                    Recent Uploads
                  </h2>
                  <button
                    onClick={() => setShowHistory(false)}
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    <FiX size={24} />
                  </button>
                </div>
              </div>
              <div className="p-6 max-h-[60vh] overflow-y-auto">
                {isLoadingHistory ? (
                  <div className="flex justify-center py-8">
                    <div className="w-8 h-8 rounded-full border-2 border-green-600 border-t-transparent animate-spin" />
                  </div>
                ) : (
                  <div className="space-y-4">
                    {recentUploads.map((upload) => (
                      <button
                        key={upload.unique_id}
                        onClick={() => {
                          navigate(`/chat/${upload.unique_id}`);
                          setShowHistory(false);
                        }}
                        className={`
                          w-full p-4 rounded-xl border transition-all duration-300 hover:shadow-lg
                          ${upload.unique_id === chatId
                            ? 'border-green-500 bg-green-50'
                            : 'border-gray-200/50 hover:border-green-300 hover:bg-white/50'
                          }
                        `}
                      >
                        <div className="flex justify-between items-start">
                          <div className="text-left">
                            <h3 className="font-medium text-gray-900">{upload.title}</h3>
                            <p className="text-sm text-gray-500 mt-1">
                              {upload.file_count} files • {upload.created_at_formatted}
                            </p>
                          </div>
                          {upload.unique_id === chatId && (
                            <span className="px-3 py-1 text-xs font-medium bg-green-600 text-white rounded-full shadow-sm">
                              Current
                            </span>
                          )}
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatPage;

