import React, { useState, useEffect, useRef } from 'react';
import { X, Copy, CheckCircle, Download, Share, Maximize2, Minimize2, BookOpen, Clock, AlertCircle, ChevronDown, FileText, File, Loader2 } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import type { Components } from 'react-markdown';
import { generatePDF } from '../utils/pdfGenerator';
import { generateDOCX } from '../utils/docxGenerator';

interface MarkdownModalProps {
  content: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  generationCompleted: boolean;
  isGenerationError: boolean;
  onDelete?: () => void;
}



const MarkdownModal: React.FC<MarkdownModalProps> = ({
  content,
  title,
  isOpen,
  onClose,
  generationCompleted,
  isGenerationError,
  onDelete,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [startTime] = useState(new Date());
  const [elapsedTime, setElapsedTime] = useState('0:00');
  const contentRef = useRef<HTMLDivElement>(null);
  const [outline, setOutline] = useState<string[]>([]);
  const [showToc, setShowToc] = useState(true);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadButtonRef = useRef<HTMLDivElement>(null);

  // Extract outline from content
  useEffect(() => {
    const lines = content.split('\n');
    const headings = lines.filter(line => line.startsWith('#')).map(line => line.replace(/^#+\s/, ''));
    setOutline(headings);
  }, [content]);

  // Handle elapsed time
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const diff = Math.floor((now.getTime() - startTime.getTime()) / 1000);
      const minutes = Math.floor(diff / 60);
      const seconds = diff % 60;
      setElapsedTime(`${minutes}:${seconds.toString().padStart(2, '0')}`);
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (downloadButtonRef.current && !downloadButtonRef.current.contains(event.target as Node)) {
        setShowDownloadOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleDownload = async (format: 'md' | 'pdf' | 'docx') => {
    setIsDownloading(true);
    try {
      let blob: Blob;
      let filename: string;
      
      switch (format) {
        case 'pdf':
          const pdfResult = await generatePDF(content, title);
          blob = pdfResult.blob;
          filename = pdfResult.filename;
          break;
        case 'docx':
          blob = await generateDOCX(content, title);
          filename = `${title.replace(/\s+/g, '_')}.docx`;
          break;
        default:
          blob = new Blob([content], { type: 'text/markdown' });
          filename = `${title.replace(/\s+/g, '_')}.md`;
      }

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating document:', error);
    } finally {
      setIsDownloading(false);
      setShowDownloadOptions(false);
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const scrollToSection = (heading: string) => {
    const element = document.getElementById(heading.toLowerCase().replace(/\s+/g, '-'));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Auto-scroll effect
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [content]);

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 ${isFullscreen ? 'p-0' : ''}`}>
      <div className={`bg-white rounded-lg shadow-xl flex flex-col ${isFullscreen ? 'w-full h-full rounded-none' : 'max-w-4xl max-h-[90vh]'}`}>
        {/* Header */}
        <div className="border-b border-gray-200 p-4 flex items-center justify-between bg-gradient-to-r from-green-600 to-green-700 text-white rounded-t-lg">
          <div className="flex items-center space-x-4">
            <BookOpen className="w-6 h-6" />
            <div>
              <h2 className="text-xl font-bold">{title}</h2>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {onDelete && (
              <button
                onClick={onDelete}
                className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors flex items-center gap-1 mr-2"
              >
                Delete Generation
              </button>
            )}
            <button
              onClick={toggleFullscreen}
              className="p-2 hover:bg-green-500 rounded-full transition-colors"
              title="Toggle Fullscreen"
            >
              {isFullscreen ? <Minimize2 className="h-5 w-5" /> : <Maximize2 className="h-5 w-5" />}
            </button>
            {isCopied ? (
              <CheckCircle className="w-5 h-5 text-green-400" />
            ) : (
              <Copy className="w-5 h-5 cursor-pointer hover:text-indigo-200" onClick={handleCopy} />
            )}
            <div className="relative" ref={downloadButtonRef}>
              <button
                onClick={() => setShowDownloadOptions(!showDownloadOptions)}
                className="flex items-center gap-1 text-white hover:text-indigo-200 transition-colors"
                disabled={isDownloading}
              >
                {isDownloading ? (
                  <div className="animate-spin">
                    <Loader2 className="w-5 h-5" />
                  </div>
                ) : (
                  <>
                    <Download className="w-5 h-5" />
                    <ChevronDown className="w-4 h-4" />
                  </>
                )}
              </button>
              
              {showDownloadOptions && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                  <div className="py-1" role="menu">
                    {[
                      { label: 'Markdown (.md)', format: 'md', icon: <FileText className="w-4 h-4" /> },
                      { label: 'PDF Document', format: 'pdf', icon: <File className="w-4 h-4" /> },
                      { label: 'Word Document', format: 'docx', icon: <File className="w-4 h-4" /> },
                    ].map((option) => (
                      <button
                        key={option.format}
                        onClick={() => handleDownload(option.format as 'md' | 'pdf' | 'docx')}
                        className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-green-50 hover:text-green-700 flex items-center gap-2 transition-colors"
                        role="menuitem"
                      >
                        {option.icon}
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-green-500 rounded-full transition-colors"
            >
              <X className="w-5 h-5 cursor-pointer hover:text-green-200" />
            </button>
          </div>
        </div>

        {/* Status Bar */}
        <div className="px-4 py-2 bg-green-50 border-b flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <span className="px-3 py-1 rounded-md">
              Outline
            </span>
            <span className="px-3 py-1 rounded-md">
              Content
            </span>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-hidden flex">
          {/* Table of Contents */}
          {showToc && (
            <div className="w-64 border-r border-gray-200 p-4 overflow-y-auto bg-green-50">
              <h3 className="font-semibold text-green-700 mb-2">Table of Contents</h3>
              <ul className="space-y-2">
                {outline.map((heading, index) => (
                  <li key={index}>
                    <button
                      onClick={() => scrollToSection(heading)}
                      className="text-sm text-gray-600 hover:text-green-600 hover:bg-green-50 w-full text-left px-2 py-1 rounded"
                    >
                      {heading}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Content Area */}
          <div className="flex-1 overflow-y-auto p-6" ref={contentRef}>
            <ReactMarkdown
              components={{
                // Simplified code component without syntax highlighting
                code: ({ className, children }) => (
                  <code className={`${className} p-2 bg-green-50 rounded`}>
                    {children}
                  </code>
                ),
                // Heading components remain the same
                h1: ({ children, ...props }) => (
                  <h1 
                    id={String(children).toLowerCase().replace(/\s+/g, '-')} 
                    className="text-3xl font-bold mb-4"
                    {...props}
                  >
                    {children}
                  </h1>
                ),
                h2: ({ children, ...props }) => (
                  <h2 
                    id={String(children).toLowerCase().replace(/\s+/g, '-')} 
                    className="text-2xl font-bold mt-6 mb-3"
                    {...props}
                  >
                    {children}
                  </h2>
                ),
                h3: ({ children, ...props }) => (
                  <h3 
                    id={String(children).toLowerCase().replace(/\s+/g, '-')} 
                    className="text-xl font-semibold mt-4 mb-2"
                    {...props}
                  >
                    {children}
                  </h3>
                ),
                div: ({ className, children, ...props }) => {
                  if (className === 'pulse-animation') {
                    return <div className="pulse-animation" {...props}>{children}</div>;
                  }
                  return <div className={className} {...props}>{children}</div>;
                },
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkdownModal;

