import React, { useEffect, useState, useRef } from 'react';
import { Socket } from 'socket.io-client';

interface DocumentContentManagerProps {
  socket: Socket | null;
  book_title: string;
  chapters: string[];
  onContentUpdate: (content: string) => void;
  onGenerationComplete: () => void;
  onError: (error: string) => void;
}

export const DocumentContentManager: React.FC<DocumentContentManagerProps> = ({
  socket,
  book_title,
  chapters,
  onContentUpdate,
  onGenerationComplete,
  onError,
}) => {
  const [accumulatedContent, setAccumulatedContent] = useState<{ [key: number]: string }>({});
  const [processedChapters, setProcessedChapters] = useState<number[]>([]);
  const isGenerationDone = useRef(false);
  const isMounted = useRef(true);

  // Reset state when socket changes or component unmounts
  useEffect(() => {
    if (!socket) {
      setAccumulatedContent({});
      setProcessedChapters([]);
      isGenerationDone.current = false;
    }

    return () => {
      setAccumulatedContent({});
      setProcessedChapters([]);
      isGenerationDone.current = false;
    };
  }, [socket]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    const handleGenerationStarted = (data: any) => {
      if (!isMounted.current) return;
      console.log("Generation started:", data);
      setAccumulatedContent({});
      setProcessedChapters([]);
      isGenerationDone.current = false;
      const initialContent = generateInitialContent(book_title);
      onContentUpdate(initialContent);
    };

    const handleChapterDelta = (data: { chapter_number: number; chapter_text: string }) => {
      if (!isMounted.current) return;
      console.log("Chapter update received:", data);
      
      // Format the chapter content - ensure headers use ###
      const formattedChapter = data.chapter_text
        .replace(/^#+\s+/gm, '### ')  // Convert any number of # to ###
        .replace(/^(\d+\.)\s+/gm, '### ') // Convert numbered sections to ###
        .replace(/^\*\*(.*?)\*\*$/gm, '### $1') // Convert **Title** to ### Title
        .replace(/^([A-Z][A-Za-z\s]+):$/gm, '### $1'); // Convert Title: to ### Title
      
      setAccumulatedContent(prev => ({
        ...prev,
        [data.chapter_number]: formattedChapter
      }));

      setProcessedChapters(prev => {
        if (prev.includes(data.chapter_number)) return prev;
        const newChapters = [...prev, data.chapter_number].sort((a, b) => a - b);
        return newChapters;
      });
    };

    const handleGenerationCompleted = () => {
      if (!isMounted.current) return;
      
      // Ensure we have all chapters before completing
      const finalContent = generateFullContent(
        book_title,
        chapters,
        accumulatedContent,
        processedChapters
      );
      
      onContentUpdate(finalContent + "\n\n### ✅ Generation Complete");
      isGenerationDone.current = true;
      onGenerationComplete();

      // Clean up socket listeners
      socket.off("generation_started", handleGenerationStarted);
      socket.off("chapter_delta", handleChapterDelta);
      socket.off("generation_completed", handleGenerationCompleted);
      socket.off("generation_error", handleError);
    };

    const handleError = (data: { error: string }) => {
      if (!isMounted.current) return;
      isGenerationDone.current = true;
      onError(data.error);
      
      // Clean up socket listeners
      socket.off("generation_started", handleGenerationStarted);
      socket.off("chapter_delta", handleChapterDelta);
      socket.off("generation_completed", handleGenerationCompleted);
      socket.off("generation_error", handleError);
    };

    // Set up socket listeners
    socket.on("generation_started", handleGenerationStarted);
    socket.on("chapter_delta", handleChapterDelta);
    socket.on("generation_completed", handleGenerationCompleted);
    socket.on("generation_error", handleError);

    // Clean up listeners on unmount or socket change
    return () => {
      socket.off("generation_started", handleGenerationStarted);
      socket.off("chapter_delta", handleChapterDelta);
      socket.off("generation_completed", handleGenerationCompleted);
      socket.off("generation_error", handleError);
      isGenerationDone.current = false;
    };
  }, [socket, book_title, chapters, onContentUpdate, onGenerationComplete, onError]);

  // Update content whenever accumulated content changes
  useEffect(() => {
    if (!isMounted.current || Object.keys(accumulatedContent).length === 0) return;

    const fullContent = generateFullContent(book_title, chapters, accumulatedContent, processedChapters);
    onContentUpdate(fullContent);
  }, [accumulatedContent, processedChapters, book_title, chapters, onContentUpdate]);

  return null;
};

// Helper functions
function generateInitialContent(title: string): string {
  return `# ${title}\n\n`;
}

function generateFullContent(
  title: string,
  chapters: string[],
  accumulatedContent: { [key: number]: string },
  processedChapters: number[]
): string {
  const initial = generateInitialContent(title);
  
  // Sort chapters to ensure they're in order
  const sortedChapters = processedChapters.sort((a, b) => a - b);
  
  const chaptersContent = sortedChapters
    .map(chapterNum => {
      const chapterContent = accumulatedContent[chapterNum];
      return chapterContent ? `\n\n${chapterContent}` : '';
    })
    .filter(content => content)
    .join('\n');

  const footer = '\n\n\n---\n' +
    '_Generated by Text Miner AI_\n' +
    `_${new Date().toLocaleDateString()}_`;

  return initial + chaptersContent + footer;
}

export default DocumentContentManager; 