import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';

const NavigationButton: React.FC<{ chatId: string }> = ({ chatId }) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(`/options/${chatId}`)}
      className="p-2 rounded-full hover:bg-gray-100 transition-all duration-200 group relative"
      title="Back to Options"
    >
      <FiHome className="w-5 h-5 text-gray-600" />
    </button>
  );
};

export default NavigationButton; 