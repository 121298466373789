import React, { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import socketService from '../services/socketService';

const TestSock: React.FC = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [logs, setLogs] = useState<string[]>([]);

  const log = (message: string) => {
    setLogs(prev => [...prev, `${new Date().toISOString()} - ${message}`]);
    console.log('[TestSock]', message);
  };

  useEffect(() => {
    const setupSocket = async () => {
      try {
        const skt = await socketService.getSocket();
        if (!skt) {
          log('Failed to connect to socket server');
          return;
        }

        setSocket(skt);

        skt.on('connect_error', (error: Error) => {
          log(`Connection error: ${error.message}`);
        });

        skt.on('error', (error: Error) => {
          log(`Socket error: ${error}`);
        });

        skt.on('reconnect_attempt', () => {
          log('Attempting to reconnect...');
        });

        skt.on('increment', (data: { num: number; progress: number }) => {
          log(`Increment received - Number: ${data.num}, Progress: ${data.progress}%`);
        });

        log(`Connected with socket ID: ${skt.id}`);

        try {
          const response = await fetch(
            `${socketService.getCurrentUrl()}/api/test?sid=${skt.id}`
          );
          const data = await response.json();
          log(`Server Response: ${JSON.stringify(data)}`);
        } catch (error) {
          log(`Error fetching test endpoint: ${error instanceof Error ? error.message : String(error)}`);
        }

        return () => {
          skt.off('connect_error');
          skt.off('error');
          skt.off('reconnect_attempt');
          skt.off('increment');
          skt.disconnect();
        };
      } catch (error) {
        log(`Socket setup error: ${error instanceof Error ? error.message : String(error)}`);
      }
    };

    const cleanup = setupSocket();
    return () => {
      cleanup?.then(cleanupFn => cleanupFn?.());
    };
  }, []);

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Socket Test</h2>
      <div className="space-y-4">
        <div className="bg-white p-4 rounded shadow">
          <h3 className="font-semibold mb-2">Connection Status</h3>
          <p>Socket Status: {socket?.connected ? 'Connected' : 'Disconnected'}</p>
          <p>Socket ID: {socket?.id || 'Not connected'}</p>
          <p>Current URL: {socketService.getCurrentUrl() || 'Not set'}</p>
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h3 className="font-semibold mb-2">Connection Logs</h3>
          <div className="max-h-60 overflow-y-auto space-y-1">
            {logs.map((logMessage, index) => (
              <p key={index} className="text-sm font-mono">
                {logMessage}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSock;