import React from 'react';
import { FiMenu, FiChevronDown } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import NavigationButton from './NavigationButton';

interface HeaderProps {
  chatId: string;
  documentMode: string;
  onDocumentModeChange: (mode: string) => void;
  onMenuToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ chatId, documentMode, onDocumentModeChange, onMenuToggle }) => {
  const navigate = useNavigate();

  return (
    <header className="bg-green-500 p-4 flex items-center justify-between relative">
      <div className="flex items-center gap-4">
        <NavigationButton chatId={chatId} />
        <button
          onClick={onMenuToggle}
          className="text-white hover:text-green-100 transition-colors duration-200 md:hidden"
        >
          <FiMenu size={24} />
        </button>
      </div>
      <h1 className="text-xl font-semibold text-white absolute left-1/2 transform -translate-x-1/2">
        {/* Chat with your documents */}
        <i>Upload or use existing documents</i>
      </h1>
      <div className="relative">
        {/* <button 
          className='bg-white hover:bg-green-100 text-green-500 font-bold py-2 px-4 rounded' 
          onClick={() => navigate(`/generate/${chatId}`)}
        >
          Generate Document
        </button> */}
        {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
          <FiChevronDown size={16} />
        </div> */}
      </div>
    </header>
  );
};

export default Header;
