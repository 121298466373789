import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import FileUploadForm from './pages/UploadPage';
import ChatPage from './pages/ChatPage';
import FileGenerationForm from './pages/FileGenerate';
import OptionsPage from './components/Options';
import TestSock from './pages/TestSock';

// Default values for environment variables
const DEFAULT_BACKEND_URL = 'http://localhost:5000/api';//'https://text-miner.amplitude.ventures/api';
const DEFAULT_SOCKET_URL = 'http://localhost:5000';//'https://text-miner.amplitude.ventures';

// Make environment variables available globally
if (!window.env) {
  window.env = {
    REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL || DEFAULT_BACKEND_URL,
    REACT_APP_SOCKET_URL: process.env.REACT_APP_SOCKET_URL || DEFAULT_SOCKET_URL,
  };
}

const App: React.FC = () => {
  useEffect(() => {
    console.log('App mounted');
    console.log("BACKEND_URL", window.env.REACT_APP_BACKEND_URL);
    console.log("SOCKET_URL", window.env.REACT_APP_SOCKET_URL);
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <Routes>
          <Route path="/" element={<FileUploadForm />} />
          <Route path="/chat/:chatId" element={<ChatPage />} />
          <Route path="/generate/:uploadId" element={<FileGenerationForm />} />
          <Route path="/options/:chatId" element={<OptionsPage />} />
          <Route path="/test" element={<TestSock />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

