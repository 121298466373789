import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';

export const generateDOCX = async (markdownContent: string, title: string): Promise<Blob> => {
  // Convert markdown content to DOCX structure
  const sections = markdownContent.split('\n');
  const docElements = sections.map(section => {
    if (section.startsWith('# ')) {
      return new Paragraph({
        text: section.replace('# ', ''),
        heading: HeadingLevel.HEADING_1,
      });
    } else if (section.startsWith('## ')) {
      return new Paragraph({
        text: section.replace('## ', ''),
        heading: HeadingLevel.HEADING_2,
      });
    } else if (section.startsWith('### ')) {
      return new Paragraph({
        text: section.replace('### ', ''),
        heading: HeadingLevel.HEADING_3,
      });
    } else {
      return new Paragraph({
        children: [new TextRun(section)],
      });
    }
  });

  const doc = new Document({
    sections: [{
      properties: {},
      children: docElements
    }],
  });

  return await Packer.toBlob(doc);
}; 